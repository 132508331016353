@font-face {
  font-family: "gillsans_r";
  src: url("/assets/tw_fonts/GillSans/GillSans-Regular.ttf");
}
@font-face {
  font-family: "Zapfino";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/Zapfino/Zapfino.ttf");
}
@font-face {
  font-family: "Zapfino_bold";
  font-style: bold;
  font-weight: 600;
  src: url("/assets/tw_fonts/Zapfino/Zapfino.ttf");
}
@font-face {
  font-family: "NeutraText";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/NeutraText/NeutraText-Book.otf");
}
@font-face {
  font-family: "NeutraText_bold";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/tw_fonts/NeutraText/NeutraText-Bold.otf");
}
@font-face {
  font-family: "Pacifico";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/Pacifico/Pacifico.ttf");
}
@font-face {
  font-family: "Pacifico_bold";
  font-style: bold;
  font-weight: 600;
  src: url("/assets/tw_fonts/Pacifico/Pacifico.ttf");
}
@font-face {
  font-family: "ACaslonPro";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/ACaslonPro/ACaslonPro-Regular.otf");
}
@font-face {
  font-family: "ACaslonPro_bold";
  font-style: bold;
  font-weight: 600;
  src: url("/assets/tw_fonts/ACaslonPro/ACaslonPro-Regular.otf");
}
@font-face {
  font-family: "OptimaLT";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/OptimaLT/Optima-LT");
}
@font-face {
  font-family: "OptimaLT_bold";
  font-style: bold;
  font-weight: 600;
  src: url("/assets/tw_fonts/OptimaLT/Optima-LT-Bold");
}
/* @font-face {
  font-family: 'AmericanTypewriter';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/tw_fonts/AmericanTypewriter/AmericanTypewriter.ttc');
}
@font-face {
  font-family: 'AmericanTypewriter_bold';
  font-style: bold;
  font-weight: 600;
  src: url('/assets/tw_fonts/AmericanTypewriter/AmericanTypewriter.ttc');
} */
@font-face {
  font-family: "American_Typewriter_Medium_BT";
  font-style: bold;
  font-weight: 600;
  src: url("/assets/tw_fonts/AmericanTypewriter/American_Typewriter_Medium_BT.ttf");
}
@font-face {
  font-family: "gillsans_sb";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/tw_fonts/GillSans/GillSans-SemiBold.ttf");
}
@font-face {
  font-family: "gillsans_light";
  src: url("/assets/tw_fonts/GillSans/GillSans-Light.ttf");
}

@font-face {
  font-family: "gillsans_bold";
  font-style: bold;
  font-weight: 800;
  src: url("/assets/tw_fonts/GillSans/GillSans-Bold.ttf");
}

@font-face {
  font-family: "avenir_black_r";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/Avenir/Avenir-Black-03.ttf");
}

@font-face {
  font-family: "avenir_book_r";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/Avenir/Avenir-Book-01.ttf");
}
@font-face {
  font-family: "avenir_bold";
  font-style: bold;
  font-weight: 800;
  src: url("/assets/tw_fonts/Avenir/Avenir-Heavy-05.ttf");
}
@font-face {
  font-family: "avenir_light";
  font-style: normal;
  font-weight: 200;
  src: url("/assets/tw_fonts/Avenir/Avenir-Light-07.ttf");
}
@font-face {
  font-family: "avenir_sb";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/tw_fonts/Avenir/Avenir-Medium-09.ttf");
}
@font-face {
  font-family: "avenir_roman";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/Avenir/Avenir-Roman-12.ttf");
}
@font-face {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/Helvetica/Helvetica.ttf");
}
@font-face {
  font-family: "LucidaGrande";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/tw_fonts/LucidaGrande/LucidaGrande.ttf");
}

body {
  margin: 0;
  font-family: "gillsans_r", "gillsans_sb", "gillsans_light", "gillsans_bold",
    "avenir_black_r", "avenir_book_r", "avenir_bold", "avenir_light",
    "avenir_sb", "avenir_roman", '"Helvetica Neue"', "Zapfino_bold", "Zapfino",
    "Pacifico", "Pacifico_bold", "ACaslonPro", "ACaslonPro_bold", "OptimaLT",
    "OptimaLT_bold", "NeutraText", "NeutraText_bold", "Helvetica",
    "LucidaGrande", "American_Typewriter_Medium_BT";
}

html {
  font-size: 100%; /* 100% of 16px = 16px */
}

p {
  margin: 0;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.MuiMenu-paper {
  height: 150px;
}
.MuiListItem-root {
  white-space: normal !important;
}
input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 293px;
  height: 2px;
  cursor: pointer;
  background-color: #a6a6a6;
  border-radius: 25px;
}
input[type="range"]::-webkit-slider-thumb {
  height: 20px;
  width: 20px;
  border-radius: 7px;
  /* background-image: url(../Capture.PNG);
  background-repeat: no-repeat; */
  background-color: #a6a6a6;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  background: #a6a6a6;
}
input[type="range"]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  background: white;
  /* background-image: url(../Capture.PNG);
  background-repeat: no-repeat; */
  background-color: #a6a6a6;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-top: -100px;
  /* border: 1px solid #ffffff; */
  border-radius: 50%;
}

.dashboard_popover > .popup-overlay {
  position: absolute !important;
}
.dontShow .track {
  background-color: transparent !important;
}
.popup-content {
  border: 1px solid transparent !important;
  box-shadow: none !important;
  background-color: transparent !important;
  width: 220px !important;
  top : 2px !important;
  left: 0px !important;
}

.dashboard_popover > .popup-overlay > .popup-content {
  width: 100% !important;
}
.general_skills_popover > .popup-overlay {
  position: fixed !important;
  overflow-y: scroll;
  height: 100%;
}
.general_skills_popover > .popup-overlay > .popup-content {
  width: 550px !important;
  height: 565px !important;
  border: solid 1.5px #410166 !important;
  background-color: #fbfdfe !important;
  margin-bottom: 200px !important;
}
.edit_rating_popover > .popup-overlay {
  position: fixed !important;
  overflow-y: scroll;
  height: 100%;
}

.edit_rating_popover > .popup-overlay > .popup-content {
  width: 800px !important;
  height: auto !important;
  border: solid 1.5px #410166 !important;
  background-color: #fbfdfe !important;
  margin-bottom: 200px !important;
}

#skills_edit_name {
  text-transform: capitalize;
  color:"#000000"
}
#search_box {
  width: 156px !important;
  padding: 5px;
}
